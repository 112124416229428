<template>
  <div class="box">
    <vue-qr :logoSrc="logo" :text="url" :size="200" style="ma"></vue-qr>
  </div>
</template>

<script>
// import url from "@/service/url-config";
import vueQr from "vue-qr";
export default {
  data() {
    return {
      url: "https://m.weiqilile.com/#/download",
      logo: require("@/img/logo-icon.png"),
    };
  },
  components: {
    vueQr,
  },
  created() {
    // this.$http.get(url.getApp).then((res) => {
    //   this.url = res.data.content[0].downloadUrl;
    // });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  background: #f5f5f5;
  min-height: calc(100vh - 80px);
  padding-top: 80px;
  text-align: center;
}
</style>
